import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LoginResult, QueryResult } from '../types';

export default async (
  tokenStorage: BaseTokenStorage,
  result?: QueryResult<LoginResult>,
): Promise<QueryResult<LoginResult>> => {
  const userResult = {
    data: {
      status: false,
    },
  };

  const response = result?.data || null;

  if (response?.status && response.token) {
    userResult.data.status = true;
    await tokenStorage.setToken(response.token);
  }

  return userResult;
};

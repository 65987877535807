import resendSignupEmail from '../graphql/resendSignupEmail';
import {
  QueryResult,
  ResendSignupEmailInput,
  ResendSignupEmailResult,
} from '../types';

export default async (
  input: ResendSignupEmailInput,
): Promise<QueryResult<ResendSignupEmailResult>> => {
  return resendSignupEmail(input);
};

import { login } from '../authApi/apple';
import resolveAuthMethod from '../graphql/resolveAuthMethod';
import { ResolveAuthResult } from '../types';

import loginCustomer from './loginCustomer';

export default async (): Promise<ResolveAuthResult | undefined> => {
  const appleLogin = await login();

  if (!appleLogin) {
    return;
  }

  const resolveResult = await resolveAuthMethod({
    appleInput: {
      code: appleLogin.code,
      user: appleLogin.user ? JSON.stringify(appleLogin.user) : undefined,
    },
  });

  return resolveResult.data;
};

export interface QueryResult<T> {
  data?: T;
  errors?: Error[];
}

// Interface for query response
export interface QueryResponse<T> {
  data: {
    data: T;
  };
  errors?: Error[];
}

export interface CoreError {
  message: string;
  type: string;
  params?: any;
  variables?: any;
  uuid?: string;
}

// GraphQL Error interface
interface Error {
  message: string;
  type: string;
  params: string;
  error?: CoreError;
}

export interface EventDetail {
  detail?: { [key: string]: any };
}

export interface GetAuthParamsCache {
  time: number;
  clientVersion: number;
}

export type ResolverAuthInput = {
  email?: string;
  type: AuthMethodType;
};

export enum AuthMethodType {
  LINK,
  RESOLVE,
}

export type AuthCallback = (
  data?: QueryResult<LinkResult | LoginResult>,
) => void;

export type UploadAvatarResult = boolean;

export type RemoveAvatarResult = boolean;

export type AppleLoginResult = {
  code: string;
  user?: any;
};

// COPYPASTED FROM `login-api-v2`
// @link https://gitlab.delfi.net/d2/api/login-api-v2/-/blob/master/src/graphql/types.ts

export type Maybe<T> = T | null;

export type InputMaybe<T> = Maybe<T>;

export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};

export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};

export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSON: any;
  BigInt: any;
  Upload: any;
};

export type Query = {
  checkEmail: CheckEmailResult;
  getAuthParams: GetAuthParamsResult;
  getCookie?: Maybe<SessionCookieResult>;
  getCustomers: CustomersWithPager;
  getSessions: SessionsResult;
  resolveAuthMethod: ResolveAuthResult;
};

export type QueryCheckEmailArgs = {
  input: CheckEmailInput;
};

export type QueryGetAuthParamsArgs = {
  input?: InputMaybe<GetAuthParamsInput>;
};

export type QueryGetCustomersArgs = {
  input?: InputMaybe<GetCustomersInput>;
  pager?: InputMaybe<PagerInput>;
  search?: InputMaybe<GetCustomersSearchInput>;
};

export type QueryGetSessionsArgs = {
  input?: InputMaybe<GetSessionsInput>;
};

export type QueryResolveAuthMethodArgs = {
  input: ResolveAuthInput;
};

export type CheckEmailInput = {
  email: Scalars['String'];
  excludeCurrent?: InputMaybe<Scalars['Boolean']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  reCaptchaVersion?: InputMaybe<ReCaptchaVersion>;
};

export type CheckEmailResult = {
  available: Scalars['Boolean'];
};

export type GetAuthParamsInput = {
  key?: InputMaybe<AuthProvider[]>;
  returnUrl?: InputMaybe<Scalars['String']>;
};

export enum AuthProvider {
  Apple = 'APPLE',
  Draugiem = 'DRAUGIEM',
  Facebook = 'FACEBOOK',
  Google = 'GOOGLE',
  Twitter = 'TWITTER',
}

export type GetAuthParamsResult = {
  apple?: Maybe<AppleParams>;
  draugiem?: Maybe<DraugiemParams>;
  facebook?: Maybe<FacebookParams>;
  google?: Maybe<GoogleParams>;
  twitter?: Maybe<TwitterParams>;
};

export type AppleParams = {
  clientId: Scalars['String'];
  scope: Scalars['String'];
  state: Scalars['String'];
};

export type DraugiemParams = {
  app: Scalars['String'];
  hash: Scalars['String'];
  redirect: Scalars['String'];
  url: Scalars['String'];
};

export type FacebookParams = {
  appId: Scalars['String'];
  scope: Scalars['String'];
};

export type GoogleParams = {
  clientId: Scalars['String'];
  scope: Scalars['String'];
};

export type TwitterParams = {
  oauthToken: Scalars['String'];
  state: Scalars['String'];
};

export type SessionCookieResult = {
  createdAt?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['String']>;
};

export type GetCustomersInput = {
  displayNames?: InputMaybe<Scalars['String'][]>;
  emails?: InputMaybe<Scalars['String'][]>;
  firstNames?: InputMaybe<Scalars['String'][]>;
  id?: InputMaybe<Scalars['Int'][]>;
  idpIds?: InputMaybe<Scalars['Int'][]>;
  lastNames?: InputMaybe<Scalars['String'][]>;
  pager?: InputMaybe<PagerInput>;
  profileIds?: InputMaybe<Scalars['Int'][]>;
  search?: InputMaybe<GetCustomersSearchInput>;
  tokens?: InputMaybe<Scalars['String'][]>;
};

export type PagerInput = {
  count?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<CustomerOrderFields>;
  orderDirection?: InputMaybe<OrderDirection>;
};

export enum CustomerOrderFields {
  DisplayName = 'DISPLAY_NAME',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  LastName = 'LAST_NAME',
  Relevance = 'RELEVANCE',
}

export enum OrderDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type GetCustomersSearchInput = {
  condition?: InputMaybe<ConditionType>;
  field?: InputMaybe<CustomerSearchFields[]>;
  useFulltextIndex?: InputMaybe<Scalars['Boolean']>;
  value: Scalars['String'];
};

export enum ConditionType {
  And = 'AND',
  Or = 'OR',
}

export enum CustomerSearchFields {
  DisplayName = 'DISPLAY_NAME',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  Id = 'ID',
  LastName = 'LAST_NAME',
}

export type CustomersWithPager = {
  items: Maybe<CustomerResult>[];
  pager: Pager;
};

export type CustomerResult = {
  appleEmail?: Maybe<Scalars['String']>;
  appleUserId?: Maybe<Scalars['String']>;
  avatar?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  displayName?: Maybe<Scalars['String']>;
  draugiemUserId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  emailLastVerifiedAt?: Maybe<Scalars['Date']>;
  emailTmp?: Maybe<Scalars['String']>;
  emailVerified?: Maybe<Scalars['Boolean']>;
  facebookEmail?: Maybe<Scalars['String']>;
  facebookUserId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  googleEmail?: Maybe<Scalars['String']>;
  googleUserId?: Maybe<Scalars['String']>;
  hasApple?: Maybe<Scalars['Boolean']>;
  hasDraugiem?: Maybe<Scalars['Boolean']>;
  hasFacebook?: Maybe<Scalars['Boolean']>;
  hasGoogle?: Maybe<Scalars['Boolean']>;
  hasPassword?: Maybe<Scalars['Boolean']>;
  hasTwitter?: Maybe<Scalars['Boolean']>;
  id?: Maybe<Scalars['Int']>;
  lastActivityTimestamp?: Maybe<Scalars['Date']>;
  lastLoginTimestamp?: Maybe<Scalars['Date']>;
  lastName?: Maybe<Scalars['String']>;
  orders?: Maybe<Scalars['JSON']>;
  phone?: Maybe<Scalars['String']>;
  reVerificationEmailSentAt?: Maybe<Scalars['Date']>;
  sessions: SessionsResult;
  twitterUserId?: Maybe<Scalars['String']>;
};

export type CustomerResultSessionsArgs = {
  input?: InputMaybe<GetSessionsInput>;
};

export type GetSessionsInput = {
  customerId?: InputMaybe<Scalars['Int']>;
  hostname?: InputMaybe<Scalars['String']>;
  lastCheckFrom?: InputMaybe<Scalars['Date']>;
  lastCheckTo?: InputMaybe<Scalars['Date']>;
  pager?: InputMaybe<PagerInput>;
};

export type SessionsResult = {
  items: Session[];
  pager: Pager;
};

export type Session = {
  customer: CustomerResult;
  id: Scalars['String'];
  isCurrentSession: Scalars['Boolean'];
  lastCheck: Scalars['Date'];
  lastIp: Scalars['BigInt'];
  lastIpStr: Scalars['String'];
  lastUserAgent: Scalars['String'];
};

export type Pager = {
  count: Scalars['Int'];
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type ResolveAuthInput = {
  appleInput?: InputMaybe<AppleInput>;
  draugiemInput?: InputMaybe<DraugiemInput>;
  facebookInput?: InputMaybe<FacebookInput>;
  googleInput?: InputMaybe<GoogleInput>;
  twitterInput?: InputMaybe<TwitterInput>;
};

export type AppleInput = {
  code: Scalars['String'];
  user?: InputMaybe<Scalars['String']>;
};

export type DraugiemInput = {
  authCode: Scalars['String'];
};

export type FacebookInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  code?: InputMaybe<Scalars['String']>;
  redirectUri?: InputMaybe<Scalars['String']>;
};

export type GoogleInput = {
  code: Scalars['String'];
  redirectUri: Scalars['String'];
};

export type TwitterInput = {
  oauthToken: Scalars['String'];
  oauthVerifier: Scalars['String'];
  state: Scalars['String'];
};

export type ResolveAuthResult = {
  authToken?: Maybe<Scalars['String']>;
  customerExists?: Maybe<Scalars['Boolean']>;
  emailAvailable?: Maybe<Scalars['Boolean']>;
  emailProvided?: Maybe<Scalars['Boolean']>;
};

export type Mutation = {
  createLoginToken: CreateLoginTokenResult;
  deleteCustomer: DeleteCustomerResult;
  linkAuthMethod: LinkResult;
  login: LoginResult;
  loginAuthMethod: LoginResult;
  loginWithToken: LoginWithTokenResult;
  logout: LogoutResult;
  recoverCustomer: RecoverCustomerResult;
  recoverPassword: RecoverPasswordResult;
  removeAvatar: Scalars['Boolean'];
  /**
   * Resend changed email verification email. Requires active user session.
   *
   * Returns true on success, false if previous email was sent less than 5 minutes ago.
   *
   * Throws errors:
   * - EmailNotFoundError - unverified email address not found
   * - AuthorizationError - missing user session
   * - MissingCodeError - code is missing or expired
   */
  resendEmailChangeEmail: ResendEmailChangeEmailResult;
  /**
   * Resend signup confirmation email.
   *
   * Returns true on success, false if previous email was sent less than 5 minutes ago.
   *
   * Throws errors:
   * - EmailNotFoundError - unverified email address not found
   * - AuthorizationError - missing user session
   * - MissingCodeError - code is missing or expired
   */
  resendSignupEmail: ResendSignupEmailResult;
  resetPassword: ResetPasswordResult;
  revertEmailChange: RevertEmailResult;
  revokeSessions: RevokeSessionsResult;
  sendDeleteCustomerEmail: SendDeleteCustomerEmailResult;
  sendReVerificationEmail: SendReVerificationEmailResult;
  setCookie?: Maybe<SessionCookieResult>;
  signup: SignupResult;
  signupAuthMethod: SignupResult;
  unlinkAuthMethod: UnlinkResult;
  updateCustomer: UpdateCustomerResult;
  uploadAvatar: Scalars['Boolean'];
  verifyEmail: VerifyEmailResult;
};

export type MutationCreateLoginTokenArgs = {
  input: CreateLoginTokenInput;
};

export type MutationDeleteCustomerArgs = {
  input: DeleteCustomerInput;
};

export type MutationLinkAuthMethodArgs = {
  input: LinkAuthMethodInput;
};

export type MutationLoginArgs = {
  input: LoginInput;
};

export type MutationLoginAuthMethodArgs = {
  input: LoginAuthMethodInput;
};

export type MutationLoginWithTokenArgs = {
  input: LoginWithTokenInput;
};

export type MutationRecoverCustomerArgs = {
  input: RecoverCustomerInput;
};

export type MutationRecoverPasswordArgs = {
  input: RecoverPasswordInput;
};

export type MutationRemoveAvatarArgs = {
  input?: InputMaybe<RemoveAvatarInput>;
};

export type MutationResendEmailChangeEmailArgs = {
  input: ResendEmailChangeEmailInput;
};

export type MutationResendSignupEmailArgs = {
  input: ResendSignupEmailInput;
};

export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};

export type MutationRevertEmailChangeArgs = {
  input: RevertEmailInput;
};

export type MutationRevokeSessionsArgs = {
  input: RevokeSessionsInput;
};

export type MutationSendDeleteCustomerEmailArgs = {
  input: SendDeleteCustomerEmailInput;
};

export type MutationSendReVerificationEmailArgs = {
  input: SendReVerificationEmailInput;
};

export type MutationSignupArgs = {
  input: SignupInput;
};

export type MutationSignupAuthMethodArgs = {
  input: SignupAuthMethodInput;
};

export type MutationUnlinkAuthMethodArgs = {
  input: UnlinkAuthMethodInput;
};

export type MutationUpdateCustomerArgs = {
  input: UpdateCustomerInput;
};

export type MutationUploadAvatarArgs = {
  file: Scalars['Upload'];
};

export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

export type CreateLoginTokenInput = {
  email: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  reCaptchaVersion?: InputMaybe<ReCaptchaVersion>;
};

export type CreateLoginTokenResult = {
  success: Scalars['Boolean'];
};

export type DeleteCustomerInput = {
  code?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
};

export type DeleteCustomerResult = {
  success: Scalars['Boolean'];
};

export type LinkAuthMethodInput = {
  authToken: Scalars['String'];
};

export type LinkResult = {
  status: Scalars['Boolean'];
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  reCaptchaVersion?: InputMaybe<ReCaptchaVersion>;
};

export type LoginResult = {
  status: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type LoginAuthMethodInput = {
  authToken: Scalars['String'];
};

export type LoginWithTokenInput = {
  token: Scalars['String'];
};

export type LoginWithTokenResult = {
  status: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type LogoutResult = {
  success: Scalars['Boolean'];
};

export type RecoverCustomerInput = {
  language?: InputMaybe<Scalars['String']>;
};

export type RecoverCustomerResult = {
  success: Scalars['Boolean'];
};

export type RecoverPasswordInput = {
  email: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  reCaptchaToken?: InputMaybe<Scalars['String']>;
  reCaptchaVersion?: InputMaybe<ReCaptchaVersion>;
};

export enum ReCaptchaVersion {
  V2 = 'v2',
  V3 = 'v3',
}

export type RecoverPasswordResult = {
  success: Scalars['Boolean'];
};

export type RemoveAvatarInput = {
  customerId: Scalars['Int'];
};

export type ResendEmailChangeEmailInput = {
  language?: InputMaybe<Scalars['String']>;
};

export type ResendEmailChangeEmailResult = {
  success: Scalars['Boolean'];
};

export type ResendSignupEmailInput = {
  email: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
};

export type ResendSignupEmailResult = {
  success: Scalars['Boolean'];
};

export type ResetPasswordInput = {
  code: Scalars['String'];
  password: Scalars['String'];
};

export type ResetPasswordResult = {
  success: Scalars['Boolean'];
};

export type RevertEmailInput = {
  language?: InputMaybe<Scalars['String']>;
  token: Scalars['String'];
};

export type RevertEmailResult = {
  success: Scalars['Boolean'];
};

export type RevokeSessionsInput = {
  customerId?: InputMaybe<Scalars['Int']>;
  excludeCurrent?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String'][]>;
};

export type RevokeSessionsResult = {
  success: Scalars['Boolean'];
};

export type SendDeleteCustomerEmailInput = {
  email?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
};

export type SendDeleteCustomerEmailResult = {
  success: Scalars['Boolean'];
};

export type SendReVerificationEmailInput = {
  email: Scalars['String'][];
  language?: InputMaybe<Scalars['String']>;
};

export type SendReVerificationEmailResult = {
  success: Scalars['Boolean'];
};

export type SignupResult = {
  status: Scalars['Boolean'];
  token?: Maybe<Scalars['String']>;
};

export type SignupAuthMethodInput = {
  authToken: Scalars['String'];
  email?: InputMaybe<Scalars['String']>;
  language?: InputMaybe<Scalars['String']>;
};

export type SignupInput = {
  email: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  reCaptchaToken?: Maybe<Scalars['String'] | Promise<Scalars['String']>>;
  reCaptchaVersion?: InputMaybe<ReCaptchaVersion>;
};

export type UnlinkAuthMethodInput = {
  authProvider: AuthProvider;
  customerId?: InputMaybe<Scalars['Int']>;
};

export type UnlinkResult = {
  status: Scalars['Boolean'];
};

export type UpdateCustomerInput = {
  displayName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  language?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  passwordNew?: InputMaybe<Scalars['String']>;
  passwordOld?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
};

export type UpdateCustomerResult = {
  success: Scalars['Boolean'];
};

export type VerifyEmailInput = {
  code: Scalars['String'];
};

export type VerifyEmailResult = {
  success: Scalars['Boolean'];
};

/** Condition that strict mode has to meet */
export enum PermissionsCondition {
  /** All of the permissions must be present */
  And = 'AND',
  /** At least one of the permissions must be present */
  Or = 'OR',
}

export type SwapSessionTokenInput = {
  token: Scalars['String'];
};

export type SwapSessionTokenResult = {
  pianoAccessToken?: Maybe<Scalars['String']>;
  pianoRefreshToken?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export const EVENT_INIT = 'login_api_init';

export const EVENT_LOGIN = 'login_api_login';

export const EVENT_LOGOUT = 'login_api_logout';

export const EVENT_CHANGE = 'login_api_change';

export const EVENT_LOGINAPI_TOKEN_CHANGED = 'loginApiTokenChanged';

export const LOGIN_API_GOOGLE_PARAMS = 'loginApiGoogleParams';

export const LOGIN_API_FACEBOOK_PARAMS = 'loginApiFacebookParams';

export const LOGIN_API_AUTH_PARAMS_CACHE = 'loginApiAuthParamsCache';

export const LOGIN_API_APPLE_PARAMS = 'loginApiAppleParams';

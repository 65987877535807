type EventNoSessionCallback = () => void;

type EventSessionCallback = (token: string) => void;

export default abstract class BaseTokenStorage {
  private token: string | undefined;

  private onLoginCallbacks: Set<EventSessionCallback>;

  private onLogoutCallbacks: Set<EventNoSessionCallback>;

  private onChangeCallbacks: Set<EventSessionCallback>;

  constructor() {
    this.onLoginCallbacks = new Set();
    this.onLogoutCallbacks = new Set();
    this.onChangeCallbacks = new Set();
  }

  public getCachedToken(): string | undefined {
    return this.token;
  }

  public async init(): Promise<void> {
    this.token = (await this.getToken()) || undefined;
  }

  abstract async getToken(): Promise<string | undefined>;

  abstract async setToken(token: string): Promise<void>;

  abstract async clearToken(): Promise<void>;

  public onLogin(cb: EventSessionCallback) {
    this.onLoginCallbacks.add(cb);
  }

  public onChange(cb: EventSessionCallback) {
    this.onChangeCallbacks.add(cb);
  }

  public onLogout(cb: EventNoSessionCallback) {
    this.onLogoutCallbacks.add(cb);
  }

  public offLogin(cb: EventSessionCallback) {
    this.onLoginCallbacks.delete(cb);
  }

  public offChange(cb: EventSessionCallback) {
    this.onChangeCallbacks.delete(cb);
  }

  public offLogout(cb: EventNoSessionCallback) {
    this.onLogoutCallbacks.delete(cb);
  }

  protected handleTokenChange(token: string | undefined) {
    const newToken = token || undefined;

    if (newToken === this.token) {
      return;
    }

    const oldToken = this.token;

    this.token = newToken;

    if (!newToken) {
      this.callCallbacks<EventNoSessionCallback>(this.onLogoutCallbacks, []);

      return;
    }

    if (!oldToken) {
      this.callCallbacks<EventSessionCallback>(this.onLoginCallbacks, [
        newToken,
      ]);
    } else {
      this.callCallbacks<EventSessionCallback>(this.onChangeCallbacks, [
        newToken,
      ]);
    }
  }

  private callCallbacks<T extends (...args: any) => any = EventSessionCallback>(
    cbs: Set<T>,
    data: Parameters<T>,
  ) {
    cbs.forEach((cb: T) => {
      try {
        // eslint-disable-next-line prefer-spread
        (cb as any).apply(null, data);
      } catch (e) {}
    });
  }
}

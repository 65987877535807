import { init as FacebookInit, login } from '../authApi/facebook';
import { getCachedFacebookParams } from '../crossLocalStorage/authParamsStorage';
import resolveAuthMethod from '../graphql/resolveAuthMethod';
import loadAuthParams from '../initialization/loadAuthParams';
import { ResolveAuthResult } from '../types';
import isFacebookNativeApp from '../util/isFacebookNativeApp';

export default async (): Promise<ResolveAuthResult | undefined> => {
  await FacebookInit();
  await loadAuthParams();

  const loginParamsResult = getCachedFacebookParams(true);
  const scope = loginParamsResult?.scope || null;

  // if we are in facebook native app then use redirecting login method
  if (isFacebookNativeApp()) {
    const appId = loginParamsResult?.appId || null;

    if (!appId) {
      return undefined;
    }

    window.parent.postMessage('loginFacebookInIframe', '*');

    return undefined;
  }

  const accessToken = await login({
    scope,
  });

  const resolveResult = await resolveAuthMethod({
    facebookInput: {
      accessToken,
    },
  });

  return resolveResult.data;
};

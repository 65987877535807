import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { CustomersWithPager, QueryResult } from '../types';

import getCustomersGraphql from './queries/getCustomers.graphql';

export default async (
  input: number | undefined,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<CustomersWithPager>> => {
  return query<CustomersWithPager>(
    getCustomersGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};

import { query } from '../loginApiClient';
import {
  QueryResult,
  ResendSignupEmailInput,
  ResendSignupEmailResult,
} from '../types';

import resendSignupEmailGraphql from './queries/resendSignupEmail.graphql';

export default async (
  input: ResendSignupEmailInput,
): Promise<QueryResult<ResendSignupEmailResult>> => {
  return query<ResendSignupEmailResult>(resendSignupEmailGraphql, {
    input,
  });
};

import { getReCaptchaToken } from '../authApi/reCaptcha';
import signup from '../graphql/signup';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, ReCaptchaVersion, SignupInput, SignupResult } from '../types';

export default async (
  tokenStorage: BaseTokenStorage,
  params: SignupInput,
  logIn = false,
): Promise<QueryResult<SignupResult>> => {
  const reCaptchaVersion = params.reCaptchaVersion ?? ReCaptchaVersion.V3;

  const reCaptchaToken = await getReCaptchaToken('signup', reCaptchaVersion as ReCaptchaVersion);

  const result = await signup(
    {
      ...params,
      reCaptchaToken,
    },
    logIn,
  );

  const token = result.data?.token || null;

  if (token) {
    await tokenStorage.setToken(token);
  }

  return result;
};

import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LinkResult, QueryResult, LinkAuthMethodInput } from '../types';

import linkAuthMethodGraphql from './queries/linkAuthMethod.graphql';


export default async (
  input: LinkAuthMethodInput,
  tokenStorage: BaseTokenStorage,
  authorize = true,
): Promise<QueryResult<LinkResult>> => {
  return query<LinkResult>(
    linkAuthMethodGraphql,
    {
      input,
    },
    authorize,
    tokenStorage,
  );
};

import {
  LOGIN_API_APPLE_PARAMS,
  LOGIN_API_AUTH_PARAMS_CACHE,
  LOGIN_API_FACEBOOK_PARAMS,
  LOGIN_API_GOOGLE_PARAMS,
} from '../constants';
import { AppleParams, FacebookParams, GoogleParams } from '../types';
import { CLIENT_VERSION } from '../util/config';

export const cacheFacebookParams = (params: FacebookParams) => {
  return localStorage.setItem(
    LOGIN_API_FACEBOOK_PARAMS,
    JSON.stringify(params),
  );
};

export const cacheGoogleParams = (params: GoogleParams) => {
  return localStorage.setItem(LOGIN_API_GOOGLE_PARAMS, JSON.stringify(params));
};

export const cacheAppleParams = (params: AppleParams) => {
  return localStorage.setItem(LOGIN_API_APPLE_PARAMS, JSON.stringify(params));
};

export const cacheAuthParamsValidation = () => {
  return localStorage.setItem(
    LOGIN_API_AUTH_PARAMS_CACHE,
    JSON.stringify({
      clientVersion: CLIENT_VERSION,
      time: +new Date(),
    }),
  );
};

export const getAuthParamsCache = () => {
  const params = localStorage.getItem(LOGIN_API_AUTH_PARAMS_CACHE);

  if (!params) {
    return;
  }

  return JSON.parse(params);
};

const isCacheValid = () => {
  const cache = getAuthParamsCache();

  if (!cache || !cache.clientVersion || !cache.time) {
    return null;
  }

  if (cache.clientVersion < CLIENT_VERSION) {
    return null;
  }

  const updateByTime = new Date();

  updateByTime.setHours(updateByTime.getHours() - 1);

  if (cache.time < updateByTime.getTime()) {
    return null;
  }

  return true;
};

const getParam = (param: string, skipCacheValidation = false) => {
  const params = localStorage.getItem(param);

  if (!params) {
    return null;
  }

  const isValid = isCacheValid();

  if (!skipCacheValidation && !isValid) {
    return null;
  }

  try {
    return JSON.parse(params);
  } catch (e) {
    return null;
  }
};

export const getCachedFacebookParams = (skipCacheValidation = false) => {
  return getParam(LOGIN_API_FACEBOOK_PARAMS, skipCacheValidation);
};

export const getCachedAppleParams = (skipCacheValidation = false) => {
  return getParam(LOGIN_API_APPLE_PARAMS, skipCacheValidation);
};

export const getCachedGoogleParams = (skipCacheValidation = false) => {
  return getParam(LOGIN_API_GOOGLE_PARAMS, skipCacheValidation);
};

import BaseTokenStorage from './BaseTokenStorage';

export default class InternalTokenStorage extends BaseTokenStorage {
  private internalToken: string | undefined;

  constructor(token?: string) {
    super();

    this.internalToken = token;
  }

  async clearToken(): Promise<void> {
    this.internalToken = undefined;
    this.handleTokenChange(undefined);
  }

  async getToken(): Promise<string | undefined> {
    return this.internalToken;
  }

  async setToken(token: string): Promise<void> {
    this.internalToken = token;
    this.handleTokenChange(token);
  }
}

import { getReCaptchaToken } from '../authApi/reCaptcha';
import createLoginToken from '../graphql/createLoginToken';
import {
  CreateLoginTokenInput,
  CreateLoginTokenResult,
  QueryResult,
  ReCaptchaVersion,
} from '../types';

export default async (
  params: CreateLoginTokenInput,
): Promise<QueryResult<CreateLoginTokenResult>> => {
  const reCaptchaVersion = params.reCaptchaVersion ?? ReCaptchaVersion.V3;

  const reCaptchaToken = await getReCaptchaToken('createLoginToken', reCaptchaVersion as ReCaptchaVersion);

  return createLoginToken({
    ...params,
    reCaptchaToken,
  });
};

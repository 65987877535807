import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LogoutResult, QueryResult } from '../types';

import logoutGraphql from './queries/logout.graphql';

export default async (
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<LogoutResult>> => {
  return query<LogoutResult>(logoutGraphql, {}, true, tokenStorage);
};

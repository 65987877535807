(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("http"), require("https"));
	else if(typeof define === 'function' && define.amd)
		define("LoginAPI", ["http", "https"], factory);
	else if(typeof exports === 'object')
		exports["LoginAPI"] = factory(require("http"), require("https"));
	else
		root["LoginAPI"] = factory(root["http"], root["https"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__26__) {
return 
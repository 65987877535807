import { getCachedAppleParams } from '../crossLocalStorage/authParamsStorage';
import loadAuthParams from '../initialization/loadAuthParams';
import { AppleLoginResult } from '../types';
import isBrowser from '../util/isBrowser';

import addScriptToBody from './addScriptToBody';

export const init = (callback?: () => void) => {
  if ((window as any).AppleID !== undefined) {
    if (typeof callback === 'function') {
      callback();
    }

    return;
  }

  const script = document.createElement('script');

  script.async = true;

  if (typeof callback === 'function') {
    script.onload = callback;
  }

  script.setAttribute(
    'src',
    'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js',
  );

  addScriptToBody(script);
};

export const login = (): Promise<AppleLoginResult> | undefined => {
  if (!isBrowser()) {
    return;
  }

  return new Promise(async (resolve, reject) => {
    await loadAuthParams();

    const loginApiAppleParams = getCachedAppleParams();

    if (!loginApiAppleParams?.clientId) {
      return reject();
    }

    const signInResult = (data: any) => {
      if (!data?.authorization?.code) {
        return reject();
      }

      return resolve({ code: data.authorization.code,
        user: data.user });
    };

    const AppleID = (window as any).AppleID;

    try {
      AppleID.auth.signIn().then(signInResult);
    } catch (error) {
      if (
        error.message.indexOf('The "init" function must be called first') === 0
      ) {
        AppleID.auth.init({
          clientId: loginApiAppleParams.clientId,
          scope: loginApiAppleParams.scope,
          redirectURI: `${location.protocol}//${location.hostname}`,
          state: loginApiAppleParams.state,
          usePopup: true,
        });
        AppleID.auth.signIn().then(signInResult);
      }
    }
  });
};

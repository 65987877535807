import loginAuthMethod from '../graphql/loginAuthMethod';
import resolveAuthMethod from '../graphql/resolveAuthMethod';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LoginResult, QueryResult } from '../types';
import { getOptions } from '../util/clientOptions';
import getQueryParams from '../util/getQueryParams';

const params = getQueryParams();

export default async (
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<LoginResult> | undefined> => {
  if (!params.facebookCode) {
    return;
  }

  const result = await resolveAuthMethod({
    facebookInput: {
      code: params.facebookCode,
      redirectUri: getOptions().loginWithReurl,
    },
  });

  const authToken = result?.data?.authToken;

  if (!authToken) {
    return;
  }

  // FB login with redirect method - currently supports login existing customers, no signup or linking
  if (result?.data?.customerExists) {
    const queryResult = await loginAuthMethod({
      authToken,
    });

    if (!queryResult?.data?.status || !queryResult?.data?.token) {
      return;
    }

    await tokenStorage.setToken(queryResult.data.token);

    return;
  }
};

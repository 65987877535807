import { FACEBOOK_LOCALE, LOGIN_API_URL, LOGIN_WITH_REURL } from './config';

export interface ClientOptions {
  apiUrl: string;
  facebookLocale: string;
  loginWithReurl: string;
}

const options: ClientOptions = {
  apiUrl: LOGIN_API_URL,
  facebookLocale: FACEBOOK_LOCALE,
  loginWithReurl: LOGIN_WITH_REURL,
};

export const setOptions = (o?: Partial<ClientOptions>) => {
  if (!o) {
    return;
  }

  const keys: (keyof ClientOptions)[] = [
    'apiUrl',
    'facebookLocale',
    'loginWithReurl',
  ];

  keys
    .filter(k => o[k])
    .map(k => [k, o[k]])
    .map(([k, v]: any) => {
      options[k as keyof ClientOptions] = v;
    });
};

export const getOptions = () => options;

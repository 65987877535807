import loginWithToken from '../graphql/loginWithToken';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LoginResult, LoginWithTokenInput, QueryResult } from '../types';

import loginCustomer from './loginCustomer';

export default async (
  params: LoginWithTokenInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<LoginResult>> => {
  const res = await loginWithToken(params);

  return loginCustomer(tokenStorage, res);
};

import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  UpdateCustomerInput,
  UpdateCustomerResult,
} from '../types';

import updateCustomerGraphql from './queries/updateCustomer.graphql';

export default async (
  input: UpdateCustomerInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<UpdateCustomerResult>> => {
  return query<UpdateCustomerResult>(
    updateCustomerGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};

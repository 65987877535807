import isBrowser from './isBrowser';

export default (
  url: string,
  target: string,
  features: string,
): Window | null => {
  if (!isBrowser()) {
    return null;
  }

  return window.open(url, target, features);
};

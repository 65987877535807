import recoverCustomer from '../graphql/recoverCustomer';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, RecoverCustomerResult } from '../types';

export default async (
  language: string,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<RecoverCustomerResult>> => {
  return recoverCustomer(language, tokenStorage);
};

import unlinkAuthMethod from '../graphql/unlinkAuthMethod';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { AuthProvider, QueryResult, UnlinkResult } from '../types';

export default async (
  provider: AuthProvider,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<UnlinkResult>> => {
  return unlinkAuthMethod(
    {
      authProvider: provider,
    },
    tokenStorage,
  );
};

import { query } from '../loginApiClient';
import { QueryResult, SignupAuthMethodInput, SignupResult } from '../types';

import signupAuthMethodGraphql from './queries/signupAuthMethod.graphql';

export default async (
  input: SignupAuthMethodInput,
): Promise<QueryResult<SignupResult>> => {
  return query<SignupResult>(signupAuthMethodGraphql, {
    input,
  });
};

import isBrowser from './isBrowser';

export default () => {
  if (!isBrowser()) {
    return {};
  }

  const queryParams = document.location.search.split('+').join(' ');

  const params: any = {};
  const re = /[?&]?([^=]+)=([^&]*)/g;

  let tokens;

  while ((tokens = re.exec(queryParams))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

import { EVENT_INIT } from '../constants';
import { setInited } from '../internalValues/moduleStatus';
import { CustomerResult, EventDetail, QueryResult } from '../types';
import dispatchEvent from '../util/dispatchEvent';

export default (customer?: QueryResult<CustomerResult>) => {
  const data: EventDetail = {};

  if (customer?.data) {
    data.detail = customer.data;
  }

  setInited();
  dispatchEvent(EVENT_INIT, data);

  return;
};

import sendDeleteCustomerEmail from '../graphql/sendDeleteCustomerEmail';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  SendDeleteCustomerEmailInput,
  SendDeleteCustomerEmailResult,
} from '../types';

export default async (
  input: SendDeleteCustomerEmailInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<SendDeleteCustomerEmailResult>> => {
  return sendDeleteCustomerEmail(input, tokenStorage);
};

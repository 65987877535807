import { query } from '../loginApiClient';
import { LoginResult, LoginWithTokenInput, QueryResult } from '../types';

import loginWithTokenGraphql from './queries/loginWithToken.graphql';

export default async (input: LoginWithTokenInput): Promise<QueryResult<LoginResult>> => {
  return query<LoginResult>(loginWithTokenGraphql, {
    input,
  });
};

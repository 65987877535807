import revokeSessions from '../graphql/revokeSessions';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  RevokeSessionsInput,
  RevokeSessionsResult,
} from '../types';

export default async (
  params: RevokeSessionsInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<RevokeSessionsResult>> => {
  return revokeSessions(params, tokenStorage);
};

import { QueryResponse, QueryResult } from '../types';

export default <T>(response: QueryResponse<T>): QueryResult<T> => {
  const data = response.data?.data || response.data;

  const result: QueryResult<T> = {
    data: data as T,
  };

  if (response?.errors) {
    result.errors = response.errors;
  }

  return result;
};

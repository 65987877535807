import resendEmailChangeEmail from '../graphql/resendEmailChangeEmail';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  ResendEmailChangeEmailInput,
  ResendEmailChangeEmailResult,
} from '../types';

export default async (
  input: ResendEmailChangeEmailInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<ResendEmailChangeEmailResult>> => {
  return resendEmailChangeEmail(input, tokenStorage);
};

import { query } from '../loginApiClient';
import { QueryResult, VerifyEmailInput, VerifyEmailResult } from '../types';

import verifyEmailGraphql from './queries/verifyEmail.graphql';

export default async (
  input: VerifyEmailInput,
): Promise<QueryResult<VerifyEmailResult>> => {
  return query<VerifyEmailResult>(verifyEmailGraphql, {
    input,
  });
};

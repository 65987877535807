import { query } from '../loginApiClient';
import { LoginInput, LoginResult, QueryResult } from '../types';

import loginGraphql from './queries/login.graphql';

export default async (input: LoginInput): Promise<QueryResult<LoginResult>> => {
  return query<LoginResult>(loginGraphql, {
    input,
  });
};

import updateCustomer from '../graphql/updateCustomer';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  UpdateCustomerInput,
  UpdateCustomerResult,
} from '../types';

export default async (
  params: UpdateCustomerInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<UpdateCustomerResult>> => {
  return updateCustomer(params, tokenStorage);
};

import { getOptions } from './clientOptions';
import isBrowser from './isBrowser';

const getLocation = (url: string) => {
  const match = url.match(
    /^(https?\:)\/\/(([^:\/?#]*)(?:\:([0-9]+))?)([\/]{0,1}[^?#]*)(\?[^#]*|)(#.*|)$/,
  );

  return (
    (match && {
      hash: match[7],
      host: match[2],
      hostname: match[3],
      href: url,
      pathname: match[5],
      port: match[4],
      protocol: match[1],
      search: match[6],
    }) ||
    {}
  );
};

const getMainDomain = (url: string) => url.split('.').slice(-2).join('.');
const getSubDomain = (url: string) => url.split('.').slice(-3).join('.');

const apiMainDomain = getMainDomain(
  (getLocation(getOptions().apiUrl) || {}).hostname || '',
);

const apiSubDomain = getSubDomain(
  (getLocation(getOptions().apiUrl) || {}).hostname || '',
);

export const mainDomain = isBrowser()
  ? !apiSubDomain.includes('test')
    ? getMainDomain(window.location.hostname)
    : getSubDomain(window.location.hostname)
  : undefined;

export const isMainDomainContext = apiMainDomain === mainDomain;

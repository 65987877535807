import { deleteCookie, getCookie } from './cookie';
import isBrowser from './isBrowser';

// eslint-disable-next-line @typescript-eslint/naming-convention
type cb = (params: { [key: string]: any }) => void;

const listenParamsCookie = () => {
  const params: any = getCookie('loginApiParams');

  if (!params) {
    return;
  }

  deleteCookie('loginApiParams');
  clearInterval(intervalListener);
  set.forEach((callback: cb) => callback(params));
};

const set = new Set<cb>();

let intervalListener;

if (isBrowser()) {
  window.addEventListener('message', event => {
    if (!event.data.hasOwnProperty('loginApiParams')) {
      return;
    }

    const params = event.data.loginApiParams;

    set.forEach((callback: cb) => callback(params));
  });
}

export default {
  on: (callback: cb) => {
    clearInterval(intervalListener);

    if (isBrowser()) {
      intervalListener = setInterval(listenParamsCookie, 1000);
    }

    set.add(callback);
  },
  off: (callback: cb) => {
    set.delete(callback);

    if (!set.size) {
      clearInterval(intervalListener);
    }
  },
};

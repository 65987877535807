import { isModuleInited } from '../internalValues/moduleStatus';
import BaseTokenStorage from '../storage/BaseTokenStorage';

export default async (errors: any[], tokenStorage?: BaseTokenStorage) => {
  // ignore if we use methods before client is inited
  if (!isModuleInited() || !tokenStorage) {
    return;
  }

  // delete old token if we got AuthorizationError
  if (!errors || !errors.length) {
    return;
  }

  const authError = errors.some(
    (error: any) => error.error.type === 'AuthorizationError',
  );

  if (!authError) {
    return;
  }

  await tokenStorage?.clearToken();
};

import logout from '../graphql/logout';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LogoutResult, QueryResult } from '../types';

export default async (
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<LogoutResult>> => {
  const userResult = {
    data: {
      success: false,
    },
  };

  if (!tokenStorage.getCachedToken()) {
    return userResult;
  }

  const result = await logout(tokenStorage);
  const response = result?.data || null;

  if (response) {
    userResult.data.success = true;
    await tokenStorage.clearToken();
  }

  return userResult;
};

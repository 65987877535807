import getSessions from '../graphql/getSessions';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { GetSessionsInput, QueryResult, SessionsResult } from '../types';

export default async (
  params: GetSessionsInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<SessionsResult>> => {
  return getSessions(params, tokenStorage);
};

import resolveAuthMethod from '../graphql/resolveAuthMethod';
import { AuthProvider, ResolveAuthResult } from '../types';
import { getOptions } from '../util/clientOptions';
import openUrl from '../util/openUrl';
import ParamsBus from '../util/ParamsBus';

import getAuthParams from './getAuthParams';


export default async (): Promise<ResolveAuthResult | undefined> => {
  const loginParamsResult = await getAuthParams({
    key: [AuthProvider.Twitter],
    returnUrl: getOptions().loginWithReurl,
  });

  const data = loginParamsResult?.data || null;
  const oauth = data?.twitter?.oauthToken || null;
  const state = data?.twitter?.state || null;

  if (!oauth || !state) {
    return;
  }

  return new Promise(async resolve => {
    const listener = async (loginApiParams: { [key: string]: any }) => {
      const oauthToken = loginApiParams.oauth_token;
      const oauthVerifier = loginApiParams.oauth_verifier;

      if (!oauthToken || !oauthVerifier) {
        return resolve();
      }

      const resolveResult = await resolveAuthMethod({
        twitterInput: {
          oauthToken,
          oauthVerifier,
          state,
        },
      });

      ParamsBus.off(listener);

      if (win) {
        win.close();
      }

      resolve(resolveResult.data);
    };

    // listen landing page popup events
    ParamsBus.on(listener);

    const win = openUrl(
      `https://twitter.com/oauth/authenticate?oauth_token=${oauth}`,
      'login-api-landing-page',
      'width=300,height=100',
    );
  });
};

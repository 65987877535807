import { CoreError } from '../types';

export default (e: CoreError) => {
  const error: any = new Error();

  error.message = e.message;
  error.type = e.type;
  error.params = e.params;
  error.variables = e.variables;
  error.uuid = e.uuid;

  throw error;
};

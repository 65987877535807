import {
  cacheAppleParams,
  cacheAuthParamsValidation,
  cacheFacebookParams,
  cacheGoogleParams,
  getCachedAppleParams,
  getCachedFacebookParams,
  getCachedGoogleParams,
} from '../crossLocalStorage/authParamsStorage';
import getAuthParams from '../graphql/getAuthParams';
import { AuthProvider } from '../types';

const loadAuthParams = async () => {
  const googleParams = getCachedGoogleParams();
  const facebookParams = getCachedFacebookParams();
  const appleParams = getCachedAppleParams();

  if (!googleParams || !facebookParams || !appleParams) {
    const data = await getAuthParams({
      key: [AuthProvider.Facebook, AuthProvider.Google, AuthProvider.Apple],
    });

    if (data.data?.facebook) {
      cacheFacebookParams(data.data.facebook);
    }

    if (data.data?.google) {
      cacheGoogleParams(data.data.google);
    }

    if (data.data?.apple) {
      cacheAppleParams(data.data.apple);
    }

    cacheAuthParamsValidation();
  }
};

export default async () => {
  await new Promise(resolve => {
    if (['complete', 'interactive'].includes(document.readyState)) {
      resolve(loadAuthParams());

      return;
    }

    window.addEventListener('DOMContentLoaded', () => {
      resolve(loadAuthParams());
    });
  });
};

import uploadAvatar from '../graphql/uploadAvatar';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, UploadAvatarResult } from '../types';

export default (
  fileInput: HTMLInputElement,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<UploadAvatarResult>> => {
  return uploadAvatar(fileInput, tokenStorage);
};

import { query } from '../loginApiClient';
import { LoginAuthMethodInput, LoginResult, QueryResult } from '../types';

import loginAuthMethodGraphql from './queries/loginAuthMethod.graphql';

export default async (
  input: LoginAuthMethodInput,
): Promise<QueryResult<LoginResult>> => {
  return query<LoginResult>(loginAuthMethodGraphql, {
    input,
  });
};

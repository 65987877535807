import getCustomers from '../graphql/getCustomers';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { CustomerResult, QueryResult } from '../types';

export default async (
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<any>> => {
  const response = await getCustomers(undefined, tokenStorage);

  const result: any = {
    data: {
      loginStatus: false,
    },
  };

  if (response) {
    result.data = {
      loginStatus: !(response.errors && response.errors.length),
    };

    if (result.data.loginStatus) {
      result.data = {
        ...result.data,
        ...response.data?.items[0],
      };
    } else {
      result.errors = response.errors;
    }
  }

  return result;
};

import { query } from '../loginApiClient';
import {
  QueryResult,
  RecoverPasswordInput,
  RecoverPasswordResult,
} from '../types';

import recoverPasswordGraphql from './queries/recoverPassword.graphql';

export default async (
  input: RecoverPasswordInput,
): Promise<QueryResult<RecoverPasswordResult>> => {
  return query<RecoverPasswordResult>(recoverPasswordGraphql, {
    input,
  });
};

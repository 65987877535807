/* eslint-disable max-lines-per-function */
import getCustomers from './api/getCustomers';
import resolveAuthMethod from './api/resolveAuthMethod';
import uploadAvatar from './api/uploadAvatar';
import { init as AppleInit } from './authApi/apple';
import { init as GoogleInit } from './authApi/google';
import { EVENT_CHANGE, EVENT_LOGIN, EVENT_LOGOUT } from './constants';
import linkAuthMethod from './graphql/linkAuthMethod';
import loginAuthMethod from './graphql/loginAuthMethod';
import signupAuthMethod from './graphql/signupAuthMethod';
import finishInit from './initialization/finishInit';
import handleFacebookCode from './initialization/handleFacebookCode';
import listenLoginFacebookInIframe from './initialization/listenLoginFacebookInIframe';
import LoginApiNpm, { IResolveAuthMethodResult } from './LoginApiNpm';
import ServerSideCookieTokenStorage from './storage/ServerSideCookieTokenStorage';
import {
  AuthProvider,
  EventDetail,
  QueryResult,
  UploadAvatarResult,
} from './types';
import { ClientOptions } from './util/clientOptions';
import {
  TOKEN_NAME,
} from './util/config';
import { deleteCookie, setCookie } from './util/cookie';
import dispatchEvent from './util/dispatchEvent';
import { getNativeApi, isSupportedNativeLoginProvider } from './util/nativeApp';
import throwCoreError from './util/throwCoreError';

export default class LoginApiWeb extends LoginApiNpm {
  constructor(options?: ClientOptions) {
    super(undefined, options);
  }

  public init() {
    this.inited = new Promise(async resolve => {
      this.tokenStorage = new ServerSideCookieTokenStorage();
      await this.tokenStorage.init();

      listenLoginFacebookInIframe();

      await handleFacebookCode(this.tokenStorage);

      GoogleInit(() => {
        gapi.load('client:auth2', () => {});
      });
      AppleInit();

      // trigger events on token change
      this.tokenStorage.onLogin(async () => {
        this.setLocalCookieToken(this.tokenStorage.getCachedToken());

        const response = await this.getCustomers();
        const data: EventDetail = {};

        if (response) {
          data.detail = response.data;
        }

        dispatchEvent(EVENT_LOGIN, data);
      });
      this.tokenStorage.onChange(async () => {
        this.setLocalCookieToken(this.tokenStorage.getCachedToken());

        const response = await this.getCustomers();
        const data: EventDetail = {};

        if (response) {
          data.detail = response.data;
        }

        dispatchEvent(EVENT_CHANGE, data);
      });
      this.tokenStorage.onLogout(() => {
        this.setLocalCookieToken(this.tokenStorage.getCachedToken());
        dispatchEvent(EVENT_LOGOUT);
      });

      const token = this.tokenStorage.getCachedToken();

      this.setLocalCookieToken(token);

      if (token) {
        const customer = await getCustomers(this.tokenStorage);

        finishInit(customer);
        resolve();

        return;
      }

      finishInit();
      resolve();
    });
  }

  private setLocalCookieToken(token?: string) {
    // always set TOKEN_NAME cookie, except when token already is stored as local cookie
    if (token) {
      setCookie(TOKEN_NAME, token);
    } else {
      deleteCookie(TOKEN_NAME);
    }
  }

  public isNativeApp(): boolean {
    return !!getNativeApi();
  }

  public isSupportedNativeLoginProvider(authProvider: AuthProvider): boolean {
    return isSupportedNativeLoginProvider(authProvider);
  }

  public async uploadAvatar(
    params: HTMLInputElement,
  ): Promise<QueryResult<UploadAvatarResult>> {
    await this.inited;

    return uploadAvatar(params, this.tokenStorage);
  }

  public async resolveAuthMethod(
    provider: AuthProvider,
    action: 'login' | 'signup' | 'link',
  ): Promise<IResolveAuthMethodResult | undefined> {
    await this.inited;

    const result = await resolveAuthMethod(this.tokenStorage, provider);

    if (!result?.authToken) {
      return;
    }

    const authToken = result.authToken;

    const handleErrors = (queryResult: QueryResult<any>) => {
      if (queryResult.errors?.length) {
        const error = queryResult.errors[0].error;

        if (error) {
          throwCoreError(error);
        }

        throw new Error(queryResult.errors[0].message);
      }
    };

    const actionLogin = async (): Promise<boolean> => {
      const queryResult = await loginAuthMethod({
        authToken,
      });

      handleErrors(queryResult);

      if (!queryResult?.data?.status || !queryResult?.data?.token) {
        return false;
      }

      await this.tokenStorage.setToken(queryResult.data.token);

      return true;
    };

    const actionLink = async (authorize = true): Promise<boolean> => {
      const queryResult = await linkAuthMethod(
        {
          authToken,
        },
        this.tokenStorage,
        authorize,
      );

      handleErrors(queryResult);

      return !!queryResult.data?.status;
    };

    const actionSignup = async (email?: string): Promise<boolean> => {
      const queryResult = await signupAuthMethod({
        authToken,
        email,
      });

      handleErrors(queryResult);

      if (queryResult.data?.token) {
        await this.tokenStorage.setToken(queryResult.data.token);
      }

      return !!queryResult.data?.status;
    };

    if (action === 'login') {
      const autoLinkProviders = [AuthProvider.Facebook, AuthProvider.Google];

      if (autoLinkProviders.includes(provider) && result?.customerExists !== true && result?.emailAvailable === false) {
        const automaticallyLinked = await actionLink(false);

        if (automaticallyLinked) {
          await actionLogin();
        }

        return { automaticallyLinked };
      } else if (result?.customerExists !== true) {
        return {
          signup: actionSignup,
        };
      }

      await actionLogin();

      return;
    }

    if (action === 'signup') {
      if (result?.customerExists !== false) {
        return {
          login: actionLogin,
        };
      }

      if (!result?.emailProvided || !result?.emailAvailable) {
        return {
          signup: actionSignup,
        };
      }

      try {
        await actionSignup();
      } catch (error) {
        return {
          signup: actionSignup,
        };
      }

      return;
    }

    if (action === 'link') {
      await actionLink();

      return;
    }

    return undefined;
  }

  public on(eventName: string, cb: (event: any) => void): void {
    window.addEventListener(eventName, cb);
  }

  public off(eventName: string, cb?: (event: any) => void): void {
    window.removeEventListener(eventName as any, cb as any);
  }
}

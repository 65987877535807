import { getReCaptchaToken } from '../authApi/reCaptcha';
import login from '../graphql/login';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { LoginInput, LoginResult, QueryResult, ReCaptchaVersion } from '../types';

import loginCustomer from './loginCustomer';

export default async (
  params: LoginInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<LoginResult>> => {
  const reCaptchaVersion = params.reCaptchaVersion ?? ReCaptchaVersion.V3;

  const reCaptchaToken = await getReCaptchaToken('login', reCaptchaVersion as ReCaptchaVersion);

  const res = await login({
    ...params,
    reCaptchaToken,
  });

  const loginResult = await loginCustomer(tokenStorage, res);

  return res.errors ? res : loginResult;
};

import { getCachedFacebookParams } from '../crossLocalStorage/authParamsStorage';
import { getOptions } from '../util/clientOptions';

import loadAuthParams from './loadAuthParams';

const bindEvent = (
  element: any,
  eventName: string,
  eventHandler: EventHandlerNonNull,
) => {
  if (element.addEventListener) {
    element.addEventListener(eventName, eventHandler, false);
  } else if (element.attachEvent) {
    element.attachEvent(`on${eventName}`, eventHandler);
  }
};

export default () => {
  bindEvent(window, 'message', async (event: any) => {
    if (event.data !== 'loginFacebookInIframe') {
      return;
    }

    await loadAuthParams();

    const urlParams = getCachedFacebookParams(true);
    const appId = urlParams?.appId || null;

    if (!appId) {
      return;
    }

    window.location.href =
        `https://www.facebook.com/v15.0/dialog/oauth?client_id=${appId}&redirect_uri=${encodeURIComponent(
          getOptions().loginWithReurl,
        )}&response_type=code&state=${encodeURIComponent(window.location.href)}`;
  });
};

import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  ResendEmailChangeEmailInput,
  ResendEmailChangeEmailResult,
} from '../types';

import resendEmailChangeEmailGraphql from './queries/resendEmailChangeEmail.graphql';


export default async (
  input: ResendEmailChangeEmailInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<ResendEmailChangeEmailResult>> => {
  return query<ResendEmailChangeEmailResult>(
    resendEmailChangeEmailGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};

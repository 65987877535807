import { print } from 'graphql';

import { queryUpload } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, UploadAvatarResult } from '../types';

import uploadAvatarGraphql from './queries/uploadAvatar.graphql';


export default (
  fileInput: HTMLInputElement,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<UploadAvatarResult>> => {
  const formData = new FormData();

  const data = {
    query: print(uploadAvatarGraphql),
    variables: {
      file: null,
    },
  };

  formData.set('operations', JSON.stringify(data));
  formData.set('map', '{"input":["variables.file"]}');

  if (fileInput.files?.length) {
    const file = fileInput.files[0];

    formData.append('input', file, file.name);
  }

  return queryUpload<UploadAvatarResult>(formData, tokenStorage);
};

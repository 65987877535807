import { EVENT_LOGINAPI_TOKEN_CHANGED } from '../constants';

import CustomEvent from './CustomEvent';
import isInIframe from './isInIframe';

export default (eventName: string, params: { [key: string]: any } = {}) => {
  const customEvent = CustomEvent(eventName, params);

  window.dispatchEvent(customEvent);

  if (isInIframe()) {
    window.parent.postMessage(EVENT_LOGINAPI_TOKEN_CHANGED, '*');
  }
};

import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, RemoveAvatarResult } from '../types';

import removeAvatarGraphql from './queries/removeAvatar.graphql';


export default (
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<RemoveAvatarResult>> => {
  return query<RemoveAvatarResult>(removeAvatarGraphql, {}, true, tokenStorage);
};

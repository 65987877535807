import { getCachedGoogleParams } from '../crossLocalStorage/authParamsStorage';
import loadAuthParams from '../initialization/loadAuthParams';
import isBrowser from '../util/isBrowser';

import addScriptToBody from './addScriptToBody';

export const init = (callback?: () => void) => {
  if ((window as any).gapi !== undefined) {
    if (typeof callback === 'function') {
      callback();
    }

    return;
  }

  const script = document.createElement('script');

  script.async = true;

  if (typeof callback === 'function') {
    script.onload = callback;
  }

  script.setAttribute('src', 'https://apis.google.com/js/api.js');

  addScriptToBody(script);
};

export const login = async (): Promise<string | undefined> => {
  if (!isBrowser()) {
    return;
  }

  await loadAuthParams();

  const loginApiGoogleParams = getCachedGoogleParams();

  if (!loginApiGoogleParams || !loginApiGoogleParams.clientId) {
    return '';
  }

  try {
    const auth2 = await gapi.auth2.init({
      client_id: loginApiGoogleParams.clientId,
    });

    const resp = await auth2.grantOfflineAccess({
      scope: loginApiGoogleParams.scope,
    });

    return resp.code;
  } catch (e: any) {
    if (e.error === 'idpiframe_initialization_failed') {
      console.log(e);

      const error: any = new Error();

      error.type = 'idpiframe_initialization_failed';
      error.message =
        'The Google Login failed. Please make sure that 3rd party cookies have not been blocked.';

      throw error;
    }
  }
};

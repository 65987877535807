import BaseTokenStorage from '../storage/BaseTokenStorage';
import { AuthProvider, ResolveAuthResult } from '../types';

import resolveApple from './resolveApple';
import resolveDraugiem from './resolveDraugiem';
import resolveFacebook from './resolveFacebook';
import resolveGoogle from './resolveGoogle';
import resolveTwitter from './resolveTwitter';
import triggerNativeLogin from './triggerNativeLogin';

const map = new Map<AuthProvider, () => Promise<ResolveAuthResult | undefined>>(
  [
    [AuthProvider.Facebook, resolveFacebook],
    [AuthProvider.Google, resolveGoogle],
    [AuthProvider.Twitter, resolveTwitter],
    [AuthProvider.Draugiem, resolveDraugiem],
    [AuthProvider.Apple, resolveApple],
  ],
);

export default async (
  tokenStorage: BaseTokenStorage,
  provider: AuthProvider,
): Promise<ResolveAuthResult | undefined> => {
  const nativeResolve = await triggerNativeLogin(provider);

  if (nativeResolve) {
    return nativeResolve;
  }

  const resolver = map.get(provider);

  if (!resolver) {
    return;
  }

  return resolver();
};

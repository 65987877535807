import { query } from '../loginApiClient';
import {
  CheckEmailInput,
  CheckEmailResult,
  QueryResult,
} from '../types';

import checkEmailGraphql from './queries/checkEmail.graphql';

export default async (input: CheckEmailInput): Promise<QueryResult<CheckEmailResult>> => {
  return query<CheckEmailResult>(checkEmailGraphql, {
    input,
  });
};

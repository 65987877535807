const customEvent = (event: string, params: { [key: string]: any }) => {
  const customEventParams = params || {
    bubbles: false,
    cancelable: false,
    detail: undefined,
  };

  const evt = document.createEvent('CustomEvent');

  evt.initCustomEvent(
    event,
    customEventParams.bubbles,
    customEventParams.cancelable,
    customEventParams.detail,
  );

  return evt;
};

export default (event: string, params: { [key: string]: any } = {}) => {
  const documentWindow: any = window;

  if (typeof documentWindow.CustomEvent === 'function') {
    return new documentWindow.CustomEvent(event, params);
  }

  customEvent.prototype = documentWindow.Event.prototype;

  return customEvent(event, params);
};

import { query } from '../loginApiClient';
import { QueryResult, SwapSessionTokenResult } from '../types';

import swapSessionTokenGraphql from './queries/swapSessionToken.graphql';

export default async (
  token: string,
): Promise<QueryResult<SwapSessionTokenResult>> => {
  return query<SwapSessionTokenResult>(swapSessionTokenGraphql, {
    input: {
      token,
    },
  });
};

import { AuthProvider } from '../types';

import isBrowser from './isBrowser';

const nativeCallbacks: any = {};

const receiveMessage = (event: any) => {
  let payload;

  try {
    payload = JSON.parse(event.data);
  } catch (e) {
    return;
  }

  if (
    payload?.type !== 'native-login-success' &&
    payload?.type !== 'native-login-fail'
  ) {
    return;
  }

  if (!payload.state || !nativeCallbacks.hasOwnProperty(payload.state)) {
    return;
  }

  const callback = nativeCallbacks[payload.state];

  callback(payload.type === 'native-login-success', payload.token);

  delete nativeCallbacks[payload.state];
};

if (isBrowser()) {
  (window as any).addEventListener('message', receiveMessage, false);
}

export const setNativeCallback = (state: string, callback: any) => {
  nativeCallbacks[state] = callback;
};

export const getNativeApi = () => {
  if (!isBrowser()) {
    return undefined;
  }

  const isAndroidNative = !!(window as any).AndroidWebAppMobileAPI;
  const isIOSNative = !!(window as any).iOSWebAppMobileAPI;

  if (!isAndroidNative && !isIOSNative) {
    return undefined;
  }

  const api = (window as any)[
    isAndroidNative ? 'AndroidWebAppMobileAPI' : 'iOSWebAppMobileAPI'
  ];

  if (!api.supportedLoginProviders || !api.login) {
    return undefined;
  }

  return api;
};

export const getSupportedNativeLoginProviders = () => {
  const nativeApi = getNativeApi();

  if (!nativeApi) {
    return [];
  }

  const supportedProvidersString = nativeApi.supportedLoginProviders();

  return JSON.parse(supportedProvidersString) || [];
};

export const isSupportedNativeLoginProvider = (authProvider: AuthProvider) => {
  return (
    getSupportedNativeLoginProviders().indexOf(authProvider.toLowerCase()) > -1
  );
};

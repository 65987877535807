export default class ScriptLoader {
  private readonly id: string;

  private readonly src: string;

  private isLoaded: boolean;

  constructor(src: string) {
    this.id = `reCaptcha_${Date.now().toString()}`;
    this.src = src;
    this.isLoaded = false;
  }

  loadScript() {
    return new Promise((resolve, reject) => {
      // Create script element
      const script = document.createElement('script');

      script.type = 'text/javascript';
      script.async = true;
      script.src = this.src;
      script.id = this.id;

      // Append the script to the DOM
      document.getElementsByTagName('body')[0].appendChild(script);

      // Resolve the promise once the script is loaded
      script.onload = () => {
        this.isLoaded = true;
        resolve(script);
      };

      // Catch any errors while loading the script
      script.onerror = () => {
        reject(new Error(`${this.src} failed to load.`));
      };
    });
  }

  load(): Promise<boolean> {
    return new Promise(async resolve => {
      if (!this.isLoaded) {
        try {
          await this.loadScript();
          resolve(true);
        } catch (e) {
          resolve(false);
        }
      } else {
        resolve(true);
      }
    });
  }

  unload(name?: string): void {
    const script = document.getElementById(this.id);

    if (script) {
      script.parentNode?.removeChild(script);
    }

    if (name) {
      delete (window as any)[name];
    }
  }
}

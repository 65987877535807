/* eslint-disable max-lines-per-function */
import getCookie from '../graphql/getCookie';
import setCookie from '../graphql/setCookie';
import { QueryResult, SessionCookieResult } from '../types';
import {
  POLL_LOGIN_COOKIE_INTERVAL,
  REFRESH_SERVER_COOKIE_TOKEN,
  TOKEN_NAME,
} from '../util/config';
import { getCookie as getLocalCookie } from '../util/cookie';

import BaseTokenStorage from './BaseTokenStorage';
import InternalTokenStorage from './InternalTokenStorage';


export default class ServerSideCookieTokenStorage extends BaseTokenStorage {
  private refreshPromise: Promise<any> | null = null;

  private getPromise: Promise<any> | null = null;

  constructor() {
    super();

    setInterval(async () => {
      const token = await this.getToken();

      this.handleTokenChange(token);
    }, POLL_LOGIN_COOKIE_INTERVAL * 1000);
  }

  public async init(): Promise<void> {
    await super.init();

    // If token from server is same as got with JS, then this means cookie is not HttpOnly,
    // refresh server-side cooke to make it Secure and HttpOnly
    const cachedToken = this.getCachedToken();

    if (cachedToken && cachedToken === getLocalCookie(TOKEN_NAME)) {
      await this.refreshServerCookie(cachedToken);
    }

    const localStorageEventItemName = 'loginApiChange';

    const triggerSessionChange = () =>
      localStorage.setItem(
        localStorageEventItemName,
        JSON.stringify(new Date().getTime()),
      );

    this.onAllEvents(triggerSessionChange);

    let previousValue = JSON.parse(
      localStorage.getItem(localStorageEventItemName) || '0',
    );

    window.addEventListener('storage', async () => {
      const newValue = JSON.parse(
        localStorage.getItem(localStorageEventItemName) || '0',
      );

      if (previousValue === newValue) {
        // unrelated storage change
        return;
      }

      previousValue = newValue;

      // unsubscribe token change events so we would not trigger new Storage event
      this.offAllEvents(triggerSessionChange);

      const token = await this.getToken();

      this.handleTokenChange(token);
      // re-subscribe to token change
      this.onAllEvents(triggerSessionChange);
    });
  }

  private onAllEvents(cb: () => void) {
    this.onLogin(cb);
    this.onLogout(cb);
    this.onChange(cb);
  }

  private offAllEvents(cb: () => void) {
    this.offLogin(cb);
    this.offLogout(cb);
    this.offChange(cb);
  }

  async clearToken(): Promise<void> {
    await this.deleteServerCookie();
    this.handleTokenChange(undefined);
  }

  async getToken(): Promise<string | undefined> {
    const result: QueryResult<SessionCookieResult> =
      await this.getServerCookie();

    if (!result?.data) {
      return;
    }

    const token = result.data.value || undefined;
    // This server-side cookie refresh is probably not needed as proper Secure
    // and HttpOnly cookies should not be cleared by Apple ITP
    const tokenTime = (new Date(result.data.createdAt).getTime() || 0) / 1000;
    const timestamp = new Date().getTime() / 1000;

    if (token && timestamp - tokenTime > REFRESH_SERVER_COOKIE_TOKEN) {
      const refreshResult = await this.refreshServerCookie(token);

      this.handleTokenChange(refreshResult.data?.value || undefined);
    }

    return token;
  }

  async setToken(token: string): Promise<void> {
    const result = await this.setServerCookie(token);

    this.handleTokenChange(result.data?.value || undefined);
  }

  private async refreshServerCookie(
    token: string,
  ): Promise<QueryResult<SessionCookieResult>> {
    if (!this.refreshPromise) {
      this.refreshPromise = this.setServerCookie(token).then(
        (result: QueryResult<SessionCookieResult>) => {
          this.refreshPromise = null;

          return result;
        },
      );
    }

    return this.refreshPromise;
  }

  private async getServerCookie(): Promise<QueryResult<SessionCookieResult>> {
    if (!this.getPromise) {
      this.getPromise = getCookie().then(
        (result: QueryResult<SessionCookieResult>) => {
          this.getPromise = null;

          return result;
        },
      );
    }

    return this.getPromise;
  }

  private async setServerCookie(
    token?: string,
  ): Promise<QueryResult<SessionCookieResult>> {
    const tmpStorage = new InternalTokenStorage(token);

    await tmpStorage.init();

    return setCookie(token, tmpStorage);
  }

  private async deleteServerCookie() {
    await setCookie(undefined, this);
  }
}

import { LOGIN_COOKIE_EXPIRES_DAYS } from './config';
import { mainDomain } from './domain';

export const setCookie = (key: string, value?: string) => {
  const date = new Date();

  date.setTime(
    date.getTime() + LOGIN_COOKIE_EXPIRES_DAYS * 24 * 60 * 60 * 1000,
  );

  const expires = `expires=${date.toUTCString()}`;
  let cookie = `${key}=${encodeURIComponent(value || '')};${expires};path=/;`;
  let domain = mainDomain || '';

  // this does not prepend cookie domain with dot if using "localhost", to make dev env work
  if (domain.includes('.')) {
    domain = `.${domain}`;
  }

  cookie += `domain=${domain};`;
  document.cookie = cookie;
};

export const deleteCookie = (key: string) => {
  let cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/;`;

  cookie += `domain=.${mainDomain};`;
  document.cookie = cookie;
};

/**
 * @param name
 * @link https://regex101.com/r/218jfN/1
 */
export const getCookie = (name: string) => {
  const value = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);

  return value ? decodeURIComponent(value[2]) : null;
};

export const setPianoCookie = (key: string, value?: string) => {
  const date = new Date();

  // time is 30 days
  date.setTime(
    date.getTime() + 30 * 24 * 60 * 60 * 1000,
  );

  const expires = `expires=${date.toUTCString()}`;
  let cookie = `${key}=${encodeURIComponent(value || '')};${expires};path=/;secure=true;`;
  let domain = mainDomain || '';

  // this does not prepend cookie domain with dot if using "localhost", to make dev env work
  if (domain.includes('.')) {
    domain = `.${domain}`;
  }

  cookie += `domain=${domain};`;
  document.cookie = cookie;
};

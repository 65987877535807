import { query } from '../loginApiClient';
import { QueryResult, SignupInput, SignupResult } from '../types';

import signupGraphql from './queries/signup.graphql';
import signupWithTokenGraphql from './queries/signupWithToken.graphql';

export default async (
  input: SignupInput,
  logIn = false,
): Promise<QueryResult<SignupResult>> => {
  return query<SignupResult>(logIn ? signupWithTokenGraphql : signupGraphql, {
    input,
  });
};

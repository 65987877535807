import resolveAuthMethod from '../graphql/resolveAuthMethod';
import { AuthProvider, QueryResult, ResolveAuthResult } from '../types';
import {
  getNativeApi,
  getSupportedNativeLoginProviders,
  setNativeCallback,
} from '../util/nativeApp';

const nativeTokenResolvers = new Map<
AuthProvider,
(token: string) => Promise<QueryResult<ResolveAuthResult>>
>([
    [
      AuthProvider.Facebook,
      (token: string) =>
        resolveAuthMethod({
          facebookInput: {
            accessToken: token,
          },
        }),
    ],
    [
      AuthProvider.Google,
      (token: string) =>
        resolveAuthMethod({
          googleInput: {
            code: token,
            redirectUri: '',
          },
        }),
    ],
  ]);

export default async (
  authProvider: AuthProvider,
): Promise<ResolveAuthResult | undefined> => {
  const nativeApi = getNativeApi();
  const nativeResolver = nativeTokenResolvers.get(authProvider);

  if (
    !nativeApi ||
    getSupportedNativeLoginProviders().indexOf(authProvider.toLowerCase()) <
      0 ||
    !nativeResolver
  ) {
    return;
  }

  const state = `${authProvider}-${new Date().getTime()}`;

  const payload = {
    state,
    origin: window.origin,
    provider: authProvider.toLowerCase(),
    type: 'native-login',
  };

  return new Promise((resolve, reject) => {
    setNativeCallback(state, async (status: boolean, token?: string) => {
      if (!status || !token) {
        return reject();
      }

      const result = await nativeResolver(token);

      resolve(result.data);
    });

    nativeApi.login(JSON.stringify(payload));
  });
};

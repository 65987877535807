import { ReCaptchaVersion } from '../types';
import {
  RECAPTCHA_V3_KEY,
  RECAPTCHA_V2_KEY,
  RECAPTCHA_URL,
  RECAPTCHA_ENABLED,
} from '../util/config';
import ScriptLoader from '../util/ScriptLoader';

// eslint-disable-next-line max-lines-per-function
export const getReCaptchaToken = async (
  action:
  | 'signup'
  | 'login'
  | 'checkEmail'
  | 'createLoginToken'
  | 'recoverPassword',
  reCaptchaVersion = ReCaptchaVersion.V3,
): Promise<Promise<string> | string | undefined> => {
  if (!RECAPTCHA_ENABLED) {
    return;
  }

  // Return token from if already exists
  const reCaptchaTokenFromStorage: string | undefined = (
    window as any
  )?.LoginAPI?.getReCaptchaToken();

  if (reCaptchaTokenFromStorage) {
    return reCaptchaTokenFromStorage;
  }

  const loader = new ScriptLoader(RECAPTCHA_URL);
  const recaptchaLoaded = await loader.load();
  const grecaptcha = await (window as any).grecaptcha;

  if (!recaptchaLoaded || !grecaptcha) {
    console.log('reCaptcha error: grecaptcha not loaded');

    return;
  }

  if (reCaptchaVersion === ReCaptchaVersion.V2) {
    const recaptchaV2Token = await grecaptcha.getResponse();

    if (!recaptchaV2Token) {
      return;
    }
    
    return new Promise(resolve => {
      resolve(recaptchaV2Token);

      grecaptcha.reset();
      clearRecaptchaV2();
    });
  }

  return new Promise(resolve => {
    grecaptcha.ready(() => {
      try {
        grecaptcha
          .execute(RECAPTCHA_V3_KEY, { action })
          .then((token: string) => {
            resolve(token);
          });

      } catch (e: any) {
        console.log('reCaptcha v3 error: ', e.message);
        resolve('');
      }
    });
  });
};

export const initRecaptchaV2 = async (id: string) => {
  const loader = new ScriptLoader(RECAPTCHA_URL);
  const recaptchaLoaded = await loader.load();
  const grecaptcha = await (window as any).grecaptcha;

  if (!recaptchaLoaded || !grecaptcha) {
    console.log('reCaptcha error: grecaptcha not loaded');

    return;
  }

  grecaptcha.ready(() => {
    try {
      // If v2 box not found, create it
      if (!document.getElementById('g-recaptcha')) {
        const recaptchaDiv = document.createElement('div');

        recaptchaDiv.setAttribute('id', 'g-recaptcha');

        const wrapper = document.getElementById(id) as HTMLElement;

        wrapper.appendChild(recaptchaDiv);
      // If v2 box found, use this
      } else {
        const recaptchaDiv = document.getElementById('g-recaptcha') as HTMLElement;
        const wrapper = document.getElementById(id) as HTMLElement;

        wrapper.innerHTML = '';
        wrapper.appendChild(recaptchaDiv);
      }

      grecaptcha.render('g-recaptcha', {
        sitekey: RECAPTCHA_V2_KEY,
      });
    } catch (e: any) {
      console.log('reCaptcha v2 error: ', e.message);
    }
  });
};

export const clearRecaptchaV2 = () => {
  const recaptcha = document.getElementById('g-recaptcha');
  const wrapper = recaptcha?.parentElement as HTMLElement;

  wrapper.innerHTML = '';
};

export const getRecaptchaVersion = async (id: string): Promise<Promise<string> | string | undefined> => {
  return new Promise(resolve => {
    const wrapper = document.getElementById(id) as HTMLElement;
    const recaptchaVersion = (wrapper?.firstChild as HTMLElement)?.id === 'g-recaptcha' ? 'v2' : 'v3';

    resolve(recaptchaVersion);
  });
};
 
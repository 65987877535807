import { login } from '../authApi/google';
import resolveAuthMethod from '../graphql/resolveAuthMethod';
import { ResolveAuthResult } from '../types';

export default async (): Promise<ResolveAuthResult | undefined> => {
  const code = await login();

  if (!code) {
    return;
  }

  const resolveResult = await resolveAuthMethod({
    googleInput: {
      code,
      redirectUri: `${window.location.protocol}//${window.location.host}`,
    },
  });

  return resolveResult.data;
};

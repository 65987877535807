import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  SendDeleteCustomerEmailInput,
  SendDeleteCustomerEmailResult,
} from '../types';

import sendDeleteCustomerEmailGraphql from './queries/sendDeleteCustomerEmail.graphql';

export default async (
  input: SendDeleteCustomerEmailInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<SendDeleteCustomerEmailResult>> => {
  return query<SendDeleteCustomerEmailResult>(
    sendDeleteCustomerEmailGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};

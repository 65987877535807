import { QueryResult, SessionCookieResult } from '../types';
import { getOptions } from '../util/clientOptions';

export default async (): Promise<QueryResult<SessionCookieResult>> => {
  return new Promise<QueryResult<SessionCookieResult>>(resolve => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', getOptions().apiUrl.replace('/graphql', '/cookie'));
    xhr.withCredentials = true;
    xhr.onload = async function () {
      if (this.status === 200) {
        const data = JSON.parse(this.responseText);

        resolve({
          data,
        });
      }
    };

    xhr.send();
  });
};

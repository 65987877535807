import { query } from '../loginApiClient';
import { CreateLoginTokenInput, CreateLoginTokenResult, QueryResult } from '../types';

import createLoginTokenGraphql from './queries/createLoginToken.graphql';

export default async (input: CreateLoginTokenInput): Promise<QueryResult<CreateLoginTokenResult>> => {
  return query<CreateLoginTokenResult>(createLoginTokenGraphql, {
    input,
  });
};

import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, UnlinkAuthMethodInput, UnlinkResult } from '../types';

import unlinkAuthMethodGraphql from './queries/unlinkAuthMethod.graphql';

export default async (
  input: UnlinkAuthMethodInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<UnlinkResult>> => {
  return query<UnlinkResult>(
    unlinkAuthMethodGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};

/* eslint-disable max-lines-per-function */
import http from 'http';
import https from 'https';

import { print, DocumentNode } from 'graphql';

import deleteInvalidToken from './api/deleteInvalidToken';
import createQueryResult from './graphql/createQueryResult';
import BaseTokenStorage from './storage/BaseTokenStorage';
import { QueryResult } from './types';
import { getOptions } from './util/clientOptions';

const postData = async <T>(
  data: string | FormData,
  authorize = false,
  tokenStorage?: BaseTokenStorage,
): Promise<QueryResult<T>> => {
  if (typeof XMLHttpRequest === 'undefined') {
    const url = new URL(getOptions().apiUrl);

    const options: any = {
      hostname: url.hostname,
      port: url.port,
      path: url.pathname,
      method: 'POST',
      timeout: 5000,
    };

    // Don't set these headers for FormData
    if (typeof data === 'string') {
      options.headers = {
        'Content-Type': 'application/json',
        'Content-Length': Buffer.byteLength(data),
      };
    }

    if (authorize) {
      options.headers.Authorization = `Bearer ${encodeURIComponent(
        tokenStorage?.getCachedToken() || '',
      )}`;
    }

    return new Promise((resolve, reject) => {
      const client = url.protocol === 'https:' ? https : http;

      const req = client.request(options, res => {
        res.setEncoding('utf8');

        const chunks: string[] = [];

        res.on('data', d => {
          chunks.push(d);
        });
        res.on('end', async () => {
          let result;

          try {
            result = JSON.parse(chunks.join(''));
          } catch (error) {
            console.error('LoginAPI response parse error', error);
            resolve({});

            return;
          }

          await deleteInvalidToken(result.errors, tokenStorage);
          resolve(createQueryResult<T>(result));
        });
      });

      req.on('error', error => {
        console.error('LoginAPI request error', error);
        resolve({});
      });

      req.write(data);
      req.end();
    });
  }

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.open('POST', getOptions().apiUrl);

    // Don't set this headers for FormData
    if (typeof data === 'string') {
      xhr.setRequestHeader('Content-Type', 'application/json');
    }

    xhr.withCredentials = true;

    if (authorize) {
      xhr.setRequestHeader(
        'Authorization',
        `Bearer ${encodeURIComponent(tokenStorage?.getCachedToken() || '')}`,
      );
    }

    xhr.onload = async function () {
      if (this.status === 200) {
        const result = JSON.parse(this.responseText);

        await deleteInvalidToken(result.errors, tokenStorage);
        resolve(createQueryResult<T>(result));
      }
    };

    xhr.send(data);
  });
};

export const query = <T>(
  queryString: DocumentNode,
  variables: { [key: string]: any },
  authorize = false,
  tokenStorage?: BaseTokenStorage,
): Promise<QueryResult<T>> => {
  const data = JSON.stringify(
    {
      query: print(queryString),
      variables,
    },
    null,
    2,
  );

  return postData<T>(data, authorize, tokenStorage);
};

export const queryUpload = <T>(
  formData: FormData,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<T>> => {
  return postData<T>(formData, true, tokenStorage);
};

import { query } from '../loginApiClient';
import { QueryResult, ResolveAuthInput, ResolveAuthResult } from '../types';

import resolveAuthMethodGraphql from './queries/resolveAuthMethod.graphql';

export default async (
  input: ResolveAuthInput,
): Promise<QueryResult<ResolveAuthResult>> => {
  return query<ResolveAuthResult>(resolveAuthMethodGraphql, {
    input,
  });
};

import { query } from '../loginApiClient';
import { GetAuthParamsInput, GetAuthParamsResult, QueryResult } from '../types';
import { getOptions } from '../util/clientOptions';

import getAuthParamsGraphql from './queries/getAuthParams.graphql';


export default async (
  input: GetAuthParamsInput,
): Promise<QueryResult<GetAuthParamsResult>> => {
  return query<GetAuthParamsResult>(getAuthParamsGraphql, {
    input: {
      key: input.key,
      returnUrl: getOptions().loginWithReurl,
    },
  });
};

import { query } from '../loginApiClient';
import { QueryResult, RevertEmailInput, RevertEmailResult } from '../types';

import revertEmailChangeGraphql from './queries/revertEmailChange.graphql';

export default async (
  input: RevertEmailInput,
): Promise<QueryResult<RevertEmailResult>> => {
  return query<RevertEmailResult>(revertEmailChangeGraphql, {
    input,
  });
};

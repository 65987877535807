import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, RecoverCustomerResult } from '../types';

import recoverCustomerGraphql from './queries/recoverCustomer.graphql';

export default async (
  language: string,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<RecoverCustomerResult>> => {
  return query<RecoverCustomerResult>(
    recoverCustomerGraphql,
    {
      input: { language },
    },
    true,
    tokenStorage,
  );
};

import { query } from '../loginApiClient';
import { QueryResult, ResetPasswordInput, ResetPasswordResult } from '../types';

import resetPasswordGraphql from './queries/resetPassword.graphql';

export default async (
  input: ResetPasswordInput,
): Promise<QueryResult<ResetPasswordResult>> => {
  return query<ResetPasswordResult>(resetPasswordGraphql, {
    input,
  });
};

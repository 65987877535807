import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { GetSessionsInput, QueryResult, SessionsResult } from '../types';

import getSessionsGraphql from './queries/getSessions.graphql';

export default async (
  input: GetSessionsInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<SessionsResult>> => {
  return query<SessionsResult>(
    getSessionsGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};

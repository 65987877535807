import { getReCaptchaToken } from '../authApi/reCaptcha';
import checkEmail from '../graphql/checkEmail';
import { CheckEmailInput, CheckEmailResult, QueryResult, ReCaptchaVersion } from '../types';

export default async (
  params: CheckEmailInput,
): Promise<QueryResult<CheckEmailResult>> => {
  const reCaptchaVersion = params.reCaptchaVersion ?? ReCaptchaVersion.V3;

  const reCaptchaToken = await getReCaptchaToken('checkEmail', reCaptchaVersion as ReCaptchaVersion);

  return checkEmail({
    ...params,
    reCaptchaToken,
  });
};

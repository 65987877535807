import { getReCaptchaToken } from '../authApi/reCaptcha';
import recoverPassword from '../graphql/recoverPassword';
import {
  QueryResult,
  ReCaptchaVersion,
  RecoverPasswordInput,
  RecoverPasswordResult,
} from '../types';

export default async (
  params: RecoverPasswordInput,
): Promise<QueryResult<RecoverPasswordResult>> => {
  const reCaptchaVersion = params.reCaptchaVersion ?? ReCaptchaVersion.V3;

  const reCaptchaToken = await getReCaptchaToken(
    'recoverPassword',
    reCaptchaVersion as ReCaptchaVersion,
  );

  return recoverPassword({
    ...params,
    reCaptchaToken,
  });
};

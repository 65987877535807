import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import { QueryResult, SessionCookieResult } from '../types';

import setCookieGraphql from './queries/setCookie.graphql';

export default async (
  token: string | undefined,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<SessionCookieResult>> => {
  return query<SessionCookieResult>(
    setCookieGraphql,
    {},
    !!token,
    tokenStorage,
  );
};

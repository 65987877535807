export const LOGIN_API_URL: string = process.env.LOGIN_API_URL || '';

export const TOKEN_NAME: string = process.env.TOKEN_NAME || '';

export const FACEBOOK_LOCALE: string = process.env.FACEBOOK_LOCALE || 'en_US';

export const LOGIN_WITH_REURL: string = process.env.LOGIN_WITH_REURL || '';

export const POLL_LOGIN_COOKIE_INTERVAL: number =
  Number(process.env.POLL_LOGIN_COOKIE_INTERVAL) || 30;

export const LOGIN_COOKIE_EXPIRES_DAYS: number =
  Number(process.env.LOGIN_COOKIE_EXPIRES_DAYS) || 365;

export const CLIENT_VERSION = Number(process.env.CI_PIPELINE_ID || +new Date());

export const REFRESH_SERVER_COOKIE_TOKEN = Number(
  process.env.REFRESH_SERVER_COOKIE_TOKEN || 1209600,
);

export const RECAPTCHA_V3_KEY: string = process.env.RECAPTCHA_V3_KEY || '';

export const RECAPTCHA_V2_KEY: string = process.env.RECAPTCHA_V2_KEY || '';

// https://developers.google.com/recaptcha/docs/faq#can-i-run-recaptcha-v2-and-v3-on-the-same-page
export const RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_V3_KEY}`;

export const RECAPTCHA_ENABLED: boolean =
  process.env.RECAPTCHA_ENABLED === 'true';

export const ENABLE_PIANO_TOKEN_SWAP: boolean =
  process.env.ENABLE_PIANO_TOKEN_SWAP === 'true';

export const PIANO_USER_TOKEN_NAME: string = process.env.PIANO_USER_TOKEN_NAME || '__utp';

export const PIANO_USER_SESSION_TOKEN_NAME: string = process.env.PIANO_USER_SESSION_TOKEN_NAME || '__idr';

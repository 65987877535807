import getAuthParams from '../graphql/getAuthParams';
import resolveAuthMethod from '../graphql/resolveAuthMethod';
import { AuthProvider, ResolveAuthResult } from '../types';
import { getOptions } from '../util/clientOptions';
import openUrl from '../util/openUrl';
import ParamsBus from '../util/ParamsBus';

export default async (): Promise<ResolveAuthResult | undefined> => {
  const loginParamsResult = await getAuthParams({
    key: [AuthProvider.Draugiem],
    returnUrl: getOptions().loginWithReurl,
  });

  const url = loginParamsResult?.data?.draugiem?.url || null;

  if (!url) {
    return;
  }

  return new Promise(async resolve => {
    const listener = async (loginApiParams: { [key: string]: any }) => {
      const authCode = loginApiParams.dr_auth_code;

      if (!authCode) {
        return resolve();
      }

      const resolveResult = await resolveAuthMethod({
        draugiemInput: {
          authCode,
        },
      });

      if (win) {
        win.close();
      }

      ParamsBus.off(listener);

      resolve(resolveResult.data);
    };

    // listen landing page popup events
    ParamsBus.on(listener);

    const win = openUrl(url, 'login-api-landing-page', 'width=200,height=100');
  });
};

import deleteCustomer from '../graphql/deleteCustomer';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  DeleteCustomerInput,
  DeleteCustomerResult,
  QueryResult,
} from '../types';

export default async (
  params: DeleteCustomerInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<DeleteCustomerResult>> => {
  return deleteCustomer(params, tokenStorage);
};

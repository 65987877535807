import 'polyfill-array-includes';
import 'promise-polyfill/src/polyfill';
import LoginApiNpm from './LoginApiNpm';
import LoginApiWeb from './LoginApiWeb';
import isBrowser from './util/isBrowser';

let api: LoginApiWeb | typeof LoginApiNpm = LoginApiNpm;

if (isBrowser()) {
  const win = window as any;

  api = win.LoginApi || new LoginApiWeb(win.LoginApiOptions);
}

export default api;

import isBrowser from '../util/isBrowser';

enum ModuleStatuses {
  STATUS_INIT_IN_PROGRESS = 1,
  STATUS_INITED = 2,
}

const context: any = isBrowser() ? window : {};

export const setInited = () => {
  context.LoginApiModuleStatus = ModuleStatuses.STATUS_INITED;
};

export const isModuleInited = () => {
  return context.LoginApiModuleStatus === ModuleStatuses.STATUS_INITED;
};

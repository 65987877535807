import { query } from '../loginApiClient';
import BaseTokenStorage from '../storage/BaseTokenStorage';
import {
  QueryResult,
  RevokeSessionsInput,
  RevokeSessionsResult,
} from '../types';

import revokeSessionsGraphql from './queries/revokeSessions.graphql';

export default async (
  input: RevokeSessionsInput,
  tokenStorage: BaseTokenStorage,
): Promise<QueryResult<RevokeSessionsResult>> => {
  return query<RevokeSessionsResult>(
    revokeSessionsGraphql,
    {
      input,
    },
    true,
    tokenStorage,
  );
};
